.loader-wrapper {
  position: absolute;
  left:0;
  right:0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: $dark-blue;
}

.loader {
  width: 40px;
  height: 40px;
  position: relative;
  top:50%;
  left:50%;
  margin-top:-20px;
  margin-left: -20px;
}

.stick1, .stick2, .stick3, .stick4 {
  width: 50px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite linear;
  animation: sk-cubemove 1.8s infinite linear;
}

.stick1 {
  background-color: white;
}

.stick2 {
  background-color: white;
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.stick3 {
  background-color: $soft-yellow;
  -webkit-animation-delay: -1.35s;
  animation-delay: -1.35s;
}

.stick4 {
  background-color: $soft-yellow;
  -webkit-animation-delay: -.45s;
  animation-delay: -.45s;
}

@-webkit-keyframes sk-cubemove {
  25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.9) }
  50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
  75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.9) }
  100% { -webkit-transform: rotate(-360deg) }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.9);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.9);
  } 50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  } 50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  } 75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.9);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.9);
  } 100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
