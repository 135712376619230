//nav

.nav {
  text-align: center;
}

.nav-list {
  display:inline-block;
}

.nav-item {
  opacity: 0.9;
  text-transform: uppercase;
  list-style-type: none;
  letter-spacing: 2px;
  display: inline-block;
  padding: 10px;

  a {
    text-decoration: none;
    color:#2D2D2D;
  }
  a:visited {
    color:#999;
  }
}

.social-link {
  display:block;
  width: 30px;
  fill: white;
  transition: 0.1s;
  &:hover {
    transform: scale(1.1);
  }
}

.facebook:hover {
  fill:#3b5998;
}

.instagram:hover{
  fill:#3f729b;
}

.soundcloud:hover{
  fill:#ff8800;
}

.youtube:hover {
  fill:#cd201f;
}

//footer
footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  text-align:center;
  padding: 20px;
  background: #2e3036;
  color: white;
  font-size: 14px;
  a {
    color: white;
  }
  p {
    opacity: 0.9;
  }
}
