.concerts {
  position: relative;
  overflow: hidden;
  background: url(../images/grandpalais.jpg), $dark-blue;
  background-size: cover;
  background-blend-mode: darken;
  color: white;
  background-position: center center;
  padding: 10px 20px;
  @media (max-width: $small) {
    padding: 20px 0;
  }
  h2 {
    font-size: 50px;
    @media(max-width: $small) {
      font-size: 34px;
    }
    letter-spacing: 10px;
    text-align: center;
    margin-bottom: 30px;
  }

  hr {
    background: $soft-yellow;
    height: 3px;
    border: 0;
    margin-bottom: 80px;
    width: 45%;
  }
}

.gigs__table {
  width: 100%;
  color:white;
  @media (max-width: $small) {
    font-size: 12px;
    padding: 0 30px;
  }
}

.gigs__row {
  width: 100%;
}

.gigs__location {
  text-transform: uppercase;
  color: $soft-yellow;
}

@media (max-width: $small) {
  .gigs__description {
    display: none;
  }
}
