.ghost-button {
  font-family: $text-font;
  border: 2px solid rgb(17,17,17);
  display: inline-block;
  text-decoration: none;
  padding: 19px 60px;
  margin-bottom: 14px;
  margin-right: 7px;
  margin-left: 7px;
  transition: .2s;
  opacity: 0.8;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px;
  @media (max-width: $small) {
    font-size: 13px;
    border-width: 1px;
    padding: 14px 20px;
  }
  span {
    transition: .2s;
    color: rgb(17,17,17);
  }
  transition: .2s;
  &:hover {
    border-color: rgb(17,17,17);
    background: black;
    span {color: #fff;}
    text-decoration: none;
  }
  &.inverse {
    border-color:white;
    span {
      color:white;
    }
    &:hover {
      background: #FFF;
      span {color: #2d2d2d;}
      text-decoration: none;
    }
  }
}
