html, body, ul {
  margin: 0;
  padding: 0;
  margin: 0;
  font-family: "lato", "sans-serif";
  color: #333;
}

html, body {
  height: 100%;
}

ul {
  list-style-type: none;
}

h1 {
  margin: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

.iframe-container {
  padding: 60px 0;
  text-align: center;
}

.hero__logo {
  width: 200px;
  margin-left: 80px;
  margin-top: 50px;
  position: fixed;
}

@media (max-width: 600px) {
  .hero__logo {
    margin-left: 20px;
    margin-top: 20px;
  }
}

h1 {
  text-indent: -1000000px;
}

.top-nav {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 30px;
  margin-right: 40px;
  text-align: right;
  display: flex;
}

@media (max-width: 600px) {
  .top-nav {
    display: none;
  }
}

.top-nav .top-nav__item a {
  color: #fff;
  transition: 0.3s;
  margin-right: 50px;
  font-size: 20px;
  letter-spacing: 2px;
  text-decoration: none;
  position: relative;
}

.top-nav .top-nav__item a:before {
  content: "";
  position: absolute;
  width: 100%;
  margin-top: 10px;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #fff;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.top-nav .top-nav__item a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.hero {
  height: 100%;
  width: 100%;
  background-image: url("../images/heavy.jpg");
  background-position: center 0;
  background-size: cover;
}

@-webkit-keyframes scroll-inner {
  from {
    margin-top: 15%;
    opacity: 1;
  }
  to {
    margin-top: 75%;
    opacity: 0;
  }
}

@keyframes scroll-inner {
  from {
    margin-top: 15%;
    opacity: 1;
  }
  to {
    margin-top: 75%;
    opacity: 0;
  }
}

div.mouse-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  height: 50px;
  -webkit-transform: translate(-50%, 8px);
  transform: translate(-50%, 8px);
}

div.mouse {
  position: relative;
  display: block;
  width: 20px;
  height: 30px;
  margin: 0 auto;
  border: solid 1px #fff;
  border-radius: 8px;
}

div.mouse span.scroll-down {
  display: block;
  width: 4px;
  height: 4px;
  margin: 15% auto auto;
  -webkit-animation: scroll-inner 1.5s;
  animation: scroll-inner 1.5s;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 50%;
  background: white;
}

.ghost-button {
  font-family: "lato", "sans-serif";
  border: 2px solid #111111;
  display: inline-block;
  text-decoration: none;
  padding: 19px 60px;
  margin-bottom: 14px;
  margin-right: 7px;
  margin-left: 7px;
  transition: .2s;
  opacity: 0.8;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px;
  transition: .2s;
}

@media (max-width: 375px) {
  .ghost-button {
    font-size: 13px;
    border-width: 1px;
    padding: 14px 20px;
  }
}

.ghost-button span {
  transition: .2s;
  color: #111111;
}

.ghost-button:hover {
  border-color: #111111;
  background: black;
  text-decoration: none;
}

.ghost-button:hover span {
  color: #fff;
}

.ghost-button.inverse {
  border-color: white;
}

.ghost-button.inverse span {
  color: white;
}

.ghost-button.inverse:hover {
  background: #FFF;
  text-decoration: none;
}

.ghost-button.inverse:hover span {
  color: #2d2d2d;
}

.about {
  padding: 80px 180px;
  text-align: center;
}

@media (max-width: 375px) {
  .about {
    padding: 40px 30px;
  }
}

.about-text {
  font-size: 21px;
  color: black;
  opacity: 0.95;
  margin-bottom: 50px;
  letter-spacing: 2px;
  line-height: 35px;
}

@media (max-width: 375px) {
  .about-text {
    font-size: 14px;
    letter-spacing: 1px;
  }
}

.concerts {
  position: relative;
  overflow: hidden;
  background: url(../images/grandpalais.jpg), #365986;
  background-size: cover;
  background-blend-mode: darken;
  color: white;
  background-position: center center;
  padding: 10px 20px;
}

@media (max-width: 375px) {
  .concerts {
    padding: 20px 0;
  }
}

.concerts h2 {
  font-size: 50px;
  letter-spacing: 10px;
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 375px) {
  .concerts h2 {
    font-size: 34px;
  }
}

.concerts hr {
  background: #ffda0b;
  height: 3px;
  border: 0;
  margin-bottom: 80px;
  width: 45%;
}

.gigs__table {
  width: 100%;
  color: white;
}

@media (max-width: 375px) {
  .gigs__table {
    font-size: 12px;
    padding: 0 30px;
  }
}

.gigs__row {
  width: 100%;
}

.gigs__location {
  text-transform: uppercase;
  color: #ffda0b;
}

@media (max-width: 375px) {
  .gigs__description {
    display: none;
  }
}

.partnership {
  text-align: center;
  padding: 20px 0;
}

.squarp-logo {
  width: 150px;
}

.nav {
  text-align: center;
}

.nav-list {
  display: inline-block;
}

.nav-item {
  opacity: 0.9;
  text-transform: uppercase;
  list-style-type: none;
  letter-spacing: 2px;
  display: inline-block;
  padding: 10px;
}

.nav-item a {
  text-decoration: none;
  color: #2D2D2D;
}

.nav-item a:visited {
  color: #999;
}

.social-link {
  display: block;
  width: 30px;
  fill: white;
  transition: 0.1s;
}

.social-link:hover {
  transform: scale(1.1);
}

.facebook:hover {
  fill: #3b5998;
}

.instagram:hover {
  fill: #3f729b;
}

.soundcloud:hover {
  fill: #ff8800;
}

.youtube:hover {
  fill: #cd201f;
}

footer {
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  text-align: center;
  padding: 20px;
  background: #2e3036;
  color: white;
  font-size: 14px;
}

footer a {
  color: white;
}

footer p {
  opacity: 0.9;
}

.loader-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #365986;
}

.loader {
  width: 40px;
  height: 40px;
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
}

.stick1, .stick2, .stick3, .stick4 {
  width: 50px;
  height: 3px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-cubemove 1.8s infinite linear;
  animation: sk-cubemove 1.8s infinite linear;
}

.stick1 {
  background-color: white;
}

.stick2 {
  background-color: white;
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.stick3 {
  background-color: #ffda0b;
  -webkit-animation-delay: -1.35s;
  animation-delay: -1.35s;
}

.stick4 {
  background-color: #ffda0b;
  -webkit-animation-delay: -.45s;
  animation-delay: -.45s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.9);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.9);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.9);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.9);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.9);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.9);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
