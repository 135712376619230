html, body, ul{
  margin:0;
  padding:0;
  margin:0;
  font-family: $text-font;
  color:#333;
}

html, body {
  height: 100%;
}

ul {
  list-style-type: none;
}

h1 {
  margin: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  text-transform: uppercase;
}

.iframe-container {
  padding: 60px 0;
  text-align: center;
}
