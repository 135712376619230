//about

.about {
  padding: 80px 180px;
  @media (max-width: $small) {
    padding: 40px 30px;
  }
  text-align: center;
}

.about-text{
  font-size: 21px;
  color: black;
  opacity: 0.95;
  margin-bottom: 50px;
  letter-spacing: 2px;
  line-height: 35px;
  @media (max-width: $small) {
    font-size: 14px;
    letter-spacing: 1px;
  }
}
