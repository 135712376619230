// hero
$background-image: '../images/heavy.jpg';

.hero__logo {
  width: 200px;
  margin-left: 80px;
  margin-top: 50px;
  position: fixed;
  @media (max-width: 600px) {
    margin-left: 20px;
    margin-top: 20px;
  }
}

h1 {
  text-indent: -1000000px;
}

.top-nav {
  @media (max-width: 600px) {
    display: none;
  }
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 30px;
  margin-right: 40px;
  text-align: right;
  display: flex;
  .top-nav__item {
    a {
      color: #fff;
      transition: 0.3s;
      margin-right: 50px;
      font-size: 20px;
      letter-spacing: 2px;
      text-decoration: none;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        margin-top: 10px;
        height: 2px;
        bottom: -5px;
        left: 0;
        background-color: #fff;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }
      &:hover:before {
        visibility: visible;
        transform: scaleX(1);
      }
    }
  }
}

.hero {
  height: 100%;
  width: 100%;
  background-image: url($background-image);
  background-position: center 0;
  background-size: cover;
}

// Mouse

@-webkit-keyframes scroll-inner
{
    from
    {
        margin-top: 15%;

        opacity: 1;
    }
    to
    {
        margin-top: 75%;

        opacity: 0;
    }
}
@keyframes scroll-inner
{
    from
    {
        margin-top: 15%;

        opacity: 1;
    }
    to
    {
        margin-top: 75%;

        opacity: 0;
    }
}
div.mouse-container
{
    position: absolute;
    bottom: 0;
    left: 50%;

    display: block;

    height: 50px;

    -webkit-transform: translate(-50%, 8px);
            transform: translate(-50%, 8px);
}
div.mouse
{
    position: relative;

    display: block;

    width: 20px;
    height: 30px;
    margin: 0 auto;

    border: solid 1px #fff;
    border-radius: 8px;

    span.scroll-down
    {
        display: block;

        width: 4px;
        height: 4px;
        margin: 15% auto auto;

        -webkit-animation: scroll-inner 1.5s;
                animation: scroll-inner 1.5s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;

        border-radius: 50%;
        background: white;
    }
}
